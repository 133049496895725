import styled from "styled-components";

export const SearcherContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
